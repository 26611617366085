import React from 'react'

function Recap() {
  return (
    <div>
        <div>
            <h6 className='text-[30px] font-bold text-[#F7F6FF] mt-[142px]'>Monthly <span className='text-[#C9C5FF]'>Recap</span></h6>
            <p className='text-[#9DA0BD] mb-[40px]'>January 24’ - February 24’</p>
            <div className='flex flex-row flex-wrap gap-[20px] gap-x-[40px] items-center justify-center recap'>
                <div className='flex flex-col items-center'>
                    <h6 className='font-bold text-[16px] md:text-[30px] text-[#F7F6FF]'>4 Users</h6>
                    <p className='text-[#9DA0BD] text-[10px] md:text-[20px]'>Running Vanquish</p>
                </div>
                <div className='flex flex-col items-center'>
                    <h6 className='font-bold text-[16px] md:text-[30px] text-[#F7F6FF]'>$160,000+</h6>
                    <p className='text-[#9DA0BD] text-[10px] md:text-[20px]'>Profit in 30 days</p>
                </div>
                <div className='flex flex-col items-center'>
                    <h6 className='font-bold text-[16px] md:text-[30px] text-[#F7F6FF]'>$300,000+</h6>
                    <p className='text-[#9DA0BD] text-[10px] md:text-[20px]'>All time profit</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Recap