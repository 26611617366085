import React from 'react'
import FeaturesToggle from './FeaturesToggle'
import Recap from './Recap'

function Features() {
  return (
    <div id="features" className='py-[70px] relative overflow-hidden '>
                <img className='absolute right-0 top-0 z-0' src="/images/big-logo.png"/>
                <img className='absolute z-0 top-[-15%] w-screen' src="/images/blue-glow.png"/>
        <div className="max-w-7xl mx-auto px-4">
            <h6 className='font-bold text-[30px] text-[#F7F6FF] mb-[40px]'>How we are <span className='text-[#CFCBFF] font-bold'>different</span></h6>
            <p className='font-medium text-[#9DA0BD] mb-[70px] max-w-lg mx-auto'>Our innovative approach combines cutting-edge technology with personalized user experiences. Unlike others, we offer everything required, ensuring you stay ahead in a rapidly evolving space. </p>
            <div className='flex flex-row flex-wrap gap-y-[40px] items-start justify-center justify-between mb-[74px]'>
                <div className='max-w-sm flex flex-col items-center md:items-start mx-auto'>
                    <img className='mb-[32px] max-w-[48px]' src="/images/community.png"/>
                    <p className='text-[16px] mb-[26px] text-[#F7F6FF] font-bold'>Exclusive and useful community</p>
                    <p className='text-[18px]  text-[#9DA0BD] text-center md:text-left font-light'>Join a community of forward-thinkers and industry leaders. Our members benefit from exclusive access to insider knowledge, collaborative opportunities, and support from peers who are just as dedicated to succeed.</p>
                </div>
                <div className='max-w-sm flex flex-col items-center md:items-start mx-auto'>
                    <img className='mb-[32px] max-w-[48px]' src="/images/support.png"/>
                    <p className='text-[16px] mb-[26px] text-[#F7F6FF] font-bold'>Continuous Innovation & Unwavering Support</p>
                    <p className='text-[18px]  text-[#9DA0BD] text-center md:text-left font-light'>Experience the best of both worlds with our ever-evolving  software and  24/7 support. Our developers tirelessly enhance the product, ensuring peak performance, while our support team stands by to assist you at any hour.</p>
                </div>
                <div className='max-w-sm flex flex-col items-center md:items-start mx-auto'>
                    <img className='mb-[32px] max-w-[48px]' src="/images/succeed.png"/>
                    <p className='text-[16px] mb-[26px] text-[#F7F6FF] font-bold'>Provide everything required to succeed</p>
                    <p className='text-[18px]  text-[#9DA0BD] text-center md:text-left font-light'>Our comprehensive solutions equips you with everything you need to navigate the complexities of this space. From Vanquish Bot to robust servers ensuring your operations run smoothly, we provide it all. </p>
                </div>
            </div>
            <div className='my-[90px] w-full h-[1px] bg-white/10'></div>
            <FeaturesToggle/>
            <Recap/>
            <img className='absolute z-0 bottom-[-25%] left-0 w-screen' src="/images/blue-glow.png"/>
        </div>
    </div>
  )
}

export default Features