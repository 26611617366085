import logo from './logo.svg';
import './App.css';
import Nav from './components/Nav';
import Header from './components/Header';
import Platforms from './components/Platforms';
import Features from './components/Features';
import Faq from './components/Faq';
import Footer from './components/Footer';
import Subfooter from './components/Subfooter';

function App() {
  return (
    <div className="App bg-[#04051B] min-h-screen">
      <Nav/>
      <Header/>
      <Platforms/>
      <Features/>
      <Faq/>
      <Subfooter/>
      <Footer/>
    </div>
  );
}

export default App;
