import React from 'react'

function Header() {
  return (
    <div id="header" className='pt-[50px] overflow-hidden relative min-h-[92vh]'>
        <img className='absolute z-0 top-0 w-screen' src="/images/header-bg.png"/>
        <img className='absolute z-0 bottom-[-45%] w-screen' src="/images/blue-glow.png"/>
        <div className='max-w-5xl mx-auto lg:px-4 mt-[1%]'>
            <div className='flex flex-col items-center justify-center gap-y-[30px]'>
                <img className='max-w-[200px]' src="/images/coming-soon.png"/>
                <h1 className='text-[28px] md:text-[45px] text-[#F7F6FF] font-bold md:mt-0 mt-[10%] leading-tight'>Streamline Solana Workflows <br /> with <span className='text-[#C6C1FF] font-bold'>Automation</span></h1>
                <div className='flex flex-row justify-center items-center gap-x-[16px]'>
                    {/* <a aria-disabled href="https://www.tensor.trade/trade/vanquish" className='opacity-25 z-50 relative cursor-not-allowed hover:opacity-25 hover:translate-y-[0px] transition-all '> */}
                    {/* <img className="block  max-w-[140px]" src="/images/purchase-btn.png"/> */}
                    <a target='_blank' href='https://www.tensor.trade/trade/vanquish' className='bg-[#5F55F9] max-w-[140px] w-[140px] flex flex-col items-center justify-center text-[15px]  h-[43px] text-white rounded-[8px] relative z-100'>Purchase</a>
                    {/* </a> */}
                    <a href="#features" className='opacity-75 z-50 relative hover:opacity-100 hover:translate-y-[-3px] transition-all cursor-pointer'>
                    <img className='max-w-[145px]' src="/images/explore.png"/>
                    </a>
                </div>
                <p className='text-[#9DA0BD] text-[16px] md:mt-0 mt-[10%]'>Join The Exclusive Community Today</p>
                <img className='max-w-[450px]' src="/images/header-users.png"/>
            </div>
            <img className='md:mt-[64px] mt-[28%]  absolute  max-w-[90vw] md:max-w-[52.5vw] left-1/2 transform -translate-x-1/2 header-ui   mx-auto bottom-0' src="/images/header-ui-2.png"/>
        </div>
    </div>
  )
}

export default Header