import React from 'react'
import * as Accordion from '@radix-ui/react-accordion';
import classNames from 'classnames';
import { ChevronDownIcon } from '@radix-ui/react-icons';



function Faq() {
  return (
    <div id="faq" className='mx-auto px-4 bg-[#04051B] py-[40px] border-0 border-white/20'>
        <div className='max-w-7xl mx-auto px-4'>
            <h6 className='text-[30px] text-[#F7F6FF] mb-[60px]'>Frequently Asked Questions</h6>

              <Accordion.Root className="AccordionRoot w-full space-y-[34px]" type="single"  collapsible>
              <Accordion.Item className="AccordionItem w-full " value="item-2">
      <AccordionTrigger className="text-white md:text-[20px] text-[16px] text-left font-medium border-b pb-4 border-white/20">What is Vanquish?
</AccordionTrigger>
      <AccordionContent  className="text-[#71748F] text-left  mb-2 pt-4 AccordionContent">Vanquish offers everything needed to succeed in the Solana botting space. We provide Vanquish Bot, Node, Proxies and Servers all in one pass.
</AccordionContent>
    </Accordion.Item>
    <Accordion.Item className="AccordionItem w-full " value="item-4">
      <AccordionTrigger className="text-white md:text-[20px] text-[16px] text-left font-medium border-b pb-4 border-white/20">Does a node come with the bot?
</AccordionTrigger>
      <AccordionContent className="text-[#71748F] AccordionContent text-left  mb-2 pt-4">Yes, we provide Vanquish Bot, Vanquish Node, Vanquish Proxies and Vanquish Servers to all of our holders, included in their pass.
</AccordionContent>
    </Accordion.Item>
    <Accordion.Item className="AccordionItem w-full " value="item-5">
      <AccordionTrigger className="text-white md:text-[20px] text-[16px] text-left font-medium border-b pb-4 border-white/20">How can I purchase Vanquish?
</AccordionTrigger>
      <AccordionContent className="text-[#71748F] AccordionContent text-left  mb-2 pt-4">Vanquish is purchasable via secondary marketplaces such as MagicEden and Tensor. 
</AccordionContent>
    </Accordion.Item>
    <Accordion.Item className="AccordionItem w-full " value="item-1">
      <AccordionTrigger className="text-white md:text-[20px] text-[16px] text-left font-medium border-b pb-4 border-white/20">What operating systems do you support?
</AccordionTrigger>
      <AccordionContent className="text-[#71748F] AccordionContent text-left  mb-2 pt-4">We support Windows, macOS, and Linux
 </AccordionContent>
    </Accordion.Item>

    <Accordion.Item className="AccordionItem w-full " value="item-3">
      <AccordionTrigger className="text-white md:text-[20px] text-[16px] text-left font-medium border-b pb-4 border-white/20">Can I rent out Vanquish?
</AccordionTrigger>
      <AccordionContent className="text-[#71748F] AccordionContent text-left  mb-2 pt-4">No. This is strictly prohibited, we do not allow for renting.
</AccordionContent>
    </Accordion.Item>


  </Accordion.Root>
        </div>
    </div>
  )
}

const AccordionTrigger = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Header className="AccordionHeader">
    <Accordion.Trigger
      className={classNames('AccordionTrigger', className)}
      {...props}
      ref={forwardedRef}
    >
      {children}
      <div className='drop-bg'>
      <ChevronDownIcon className="AccordionChevron" aria-hidden />
      </div>
      {/* <img src="/images/plus.png"/> */}
    </Accordion.Trigger>
  </Accordion.Header>
));

const AccordionContent = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Content
    className={classNames('AccordionContent', className)}
    {...props}
    ref={forwardedRef}
  >
    <div className="AccordionContentText">{children}</div>
  </Accordion.Content>
));


export default Faq