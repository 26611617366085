import React from 'react'
import { useState } from 'react'

const features = [
    {
        id: 0,
        title: 'Quick Tasks',
        desc: 'Effortlessly initiate and launch your tasks instantly by pressing a single button through our Quicktask solution.',
        icon: 'pencil.png',
        uiImage: 'quickui.png'
    },
    {
        id: 1,
        title: 'Real time PNL and Hotkeys',
        desc: 'Discover real-time PNL monitoring through our user-friendly solution. Instantly observe your gains and losses and execute sales effortlessly using our convenient hotkeys.',
        icon: 'pnl.png',
        uiImage: 'pnlui.png'
    },
    {
        id: 2,
        title: 'Wallet Manager',
        desc: 'Effortlessly handle your wallets using our professional solution. Easily generate, remove, import wallets, and seamlessly transfer funds and NFTs through our user-friendly interface.',
        icon: 'wallet.png',
        uiImage: 'walletui.png'
    },
]

function FeaturesToggle() {
    const [activeFeature, setActiveFeature] = useState(0)
    const img = features[activeFeature].uiImage
  return (
    <div className='flex flex-row px-4 flex-wrap gap-[40px] items-center justify-between'>
        <div className='flex  z-10 px-4 relative w-full md:w-auto flex-col items-center justify-center md:items-start gap-y-[20px]'>
            {
                features.map((feature) => {
                    console.log(feature.id === activeFeature)
                    return (
                        <div
                        onClick={() => setActiveFeature(feature.id)}
                         key={feature.id} className={`
                        ${activeFeature === feature.id ? 'gradient-border cursor-pointer' : 'bg-none cursor-pointer'} 
                        w-[473px] transition-all h-full  rounded-[20px] max-w-[100%] py-[15px] md:py-[30px] px-[10px] hover:cursor-pointer relative z-[500] md:px-[26px] flex flex-row items-start`}>
                        <img className='mr-4' src={`/images/${feature.icon}`} alt="" />
                        <div className='flex flex-col items-start'>
                            <h6 className='mb-3 text-[#F7F6FF] md:text-[20px]'>{feature.title}</h6>
                            <p className='text-[#9DA0BD] text-[14px] md:text-[18px] text-left'>{feature.desc}</p>
                        </div>
                    </div>
                    )
                })
            }
        </div>
        <img className='absolute right-0 z-0' src="/images/ui-blur.png"/>
        <img className='relative lg:max-w-[50%] z-1  mx-auto' src={`/images/${img}`}/>
    </div>
  )
}

export default FeaturesToggle