import React from 'react'
import { SiWindows } from "react-icons/si";
import { FaApple } from "react-icons/fa";
import { DiLinux } from "react-icons/di";

function Subfooter() {
  return (
    <div className='py-[74px]  px-4 relative overflow-hidden'>
        <img className='absolute z-0 top-[-50%] left-0 w-screen' src="/images/blue-glow.png"/>
        <img className='absolute z-0 bottom-[-60%] left-0 w-screen' src="/images/blue-glow.png"/>
        <img className='subfooter-ui max-w-[40%] hidden md:absolute md:block' src="/images/get-started-2.png"/>
        <div className='flex flex-row w-full max-w-7xl mx-auto gap-x-[40px] items-center'>
        <div className='max-w-7xl  flex flex-col items-center md:items-start mr-auto gap-y-[28px]'>
            <h6 className='md:text-[40px] text-[28px] font-bold text-[#F7F6FF] text-center md:text-left'>Begin your Vanquish <br /> Journey</h6>
            <div className='flex flex-row justify-center flex-wrap gap-[37px] items-center gap-x-[37px]'>
                <a href="#top" className='bg-[#5F55F9] relative z-50 text-white w-[144px] h-[42px] flex flex-col items-center justify-center rounded-[8px]'>Get Started</a>
                <div className='flex flex-row items-center gap-x-[12px]'>
                    <SiWindows color={'#A09AFF'} size={20}/>
                    <p className='text-[15px] text-[#F7F6FF]'>Windows</p>
                </div>
                <div className='flex flex-row  items-center gap-x-[12px]'>
                    <FaApple color={'#A09AFF'} size={20}/>
                    <p className='text-[15px] text-[#F7F6FF]'>Mac</p>
                </div>
                <div className='flex flex-row items-center gap-x-[12px]'>
                    <DiLinux color={'#A09AFF'} size={20}/>
                    <p className='text-[15px] text-[#F7F6FF]'>Linux</p>
                </div>
            </div>
        </div>
        <img className='subfooter-xl-ui' src="/images/footer-ui-2.png"/>
        </div>
    </div>
  )
}

export default Subfooter