import React from 'react'
import { FaTwitter, FaDiscord } from "react-icons/fa";

function Footer() {
  return (
    <div className='bg-[#1E1B48] py-[58px]'>
        <div className='max-w-7xl px-4 mx-auto items-center flex flex-col md:flex-row justify-between'>
            <div className='flex flex-col gap-y-[20px] items-start'>
            <img
            src="/images/Group_1.svg"
            className="md:w-[227px]"
            alt="Vanquish Logo"
          />
          <p className='text-[#9DA0BD] text-[15px] font-medium mt-2 opacity-75 md:text-left text-center mx-auto md:mx-0 transition-all'>Reclaiming the Throne.</p>
            </div>
            <div className='flex flex-row gap-x-[46px] my-[20px]'>
                <a href="#features" className="text-[#9DA0BD] text-[15px] font-medium mt-2 opacity-75 hover:text-white hover:opacity-100  transition-all cursor-pointer">Features</a>
                <a href="#faq" className="text-[#9DA0BD] text-[15px] font-medium mt-2 opacity-75 hover:text-white hover:opacity-100  transition-all cursor-pointer">FAQ</a>
            </div>
            <div className='flex flex-row gap-x-[46px]'>
                <a target='_blank' href="https://twitter.com/VanquishBot">
                    <FaTwitter color="white" size={30}/>
                </a>
                <a target='_blank' href="https://discord.com/invite/vanquishbot">
                    <FaDiscord color="white" size={30}/>
                </a>
            </div>
        </div>
        <div className='max-w-[95%] w-full h-[1px] bg-white/10 my-[36px] mx-auto'></div>
        <div className='max-w-7xl mx-auto items-center flex flex-col gap-y-[20px] md:flex-row justify-between'>
            <p className='text-[18px] text-[#9DA0BD]'>All rights reserved 2024, Vanquish</p>
            <div className='flex flex-row gap-x-[46px]'>
                <p className="text-[#9DA0BD] text-[15px] font-medium mt-2 opacity-75 hover:text-[#5F55F9] hover:opacity-100 hover:translate-y-[-3px] transition-all cursor-pointer">Privacy Policy</p>
                <p className="text-[#9DA0BD] text-[15px] font-medium mt-2 opacity-75 hover:text-[#5F55F9] hover:opacity-100 hover:translate-y-[-3px] transition-all cursor-pointer">Terms and Conditions</p>
            </div>
        </div>
    </div>
  )
}

export default Footer