import React from 'react'

function Platforms() {
  return (
    <div className='mx-auto relative overflow-hidden bg-[#04051B] py-[40px]'>

        <div className='max-w-3xl mx-auto'>
            <h6 className='text-[20px] text-[#F7F6FF]'>Supported Platforms</h6>
            <img src="/images/tensor2.png"/>
            <p className='text-[#71748F] text-[15px] mt-8'>+ Much More</p>
        </div>
    </div>
  )
}

export default Platforms